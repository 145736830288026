<script lang="ts">
	import type { PageData } from './$types';
	import YouTubeLite from '$components/YouTubeLite.svelte';
	import type { returnedWorkType, returnedEventType } from './+page.server';

	export let data: PageData;

	$: ({ recentWorks, upcomingEvents } = data as {
		recentWorks: returnedWorkType[];
		upcomingEvents: returnedEventType[];
	});
</script>

<svelte:head>
	<title>Sam Greening</title>
	<meta name="description" content="Sam Greening. Composer, pianist, electronic musician." />
	<link rel="preconnect" href="https://w.soundcloud.com/" />
</svelte:head>

<main class="flex flex-col justify-evenly">
	<section class="text-6xl">
		<h1 class="my-8">Sam Greening</h1>
		<h2 class="my-8">
			<a class="link-bg-hl text-red-500" href="/works" data-sveltekit-preload-data>Composer</a>
			and
			<span class="highlight">Pianist</span>
		</h2>
	</section>

	<section class="mt6">
		<a href="/works" class="text-link">
			<h3 class="my-4 border-b-2 border-current text-lg">Recent Works</h3>
		</a>
		<ul class="pa0 ma0">
			{#each recentWorks as work}
				<li class="mv2 db leading-tight">
					<a
						class="dark-gray hover-bg-washed-blue hide-child db w-100 min-h2 pv1 ph2 ma0 inline-flex items-center hover:text-blue-500"
						href="works/{work.slug}"
						data-sveltekit-preload-data
					>
						<div class="flex flex-col leading-tight">
							<div class="flex flex-row items-baseline">
								<div class="underline-child f5">{work.name}</div>
								{#if work.dateRange}
									<div class="ph2">({work.dateRange})</div>
								{/if}
							</div>
							{#if work.instrumentation}
								<div class="i">
									for {work.instrumentation}
								</div>
							{:else if work.summaryDetails}
								<div class="i">
									{work.summaryDetails}
								</div>
							{/if}
						</div>
						<div class="flex-grow" />
						<div class="child tr f3">&gt;</div>
					</a>
				</li>
			{/each}
		</ul>
	</section>

	{#if upcomingEvents && upcomingEvents.length}
		<section class="mt6">
			<a href="/events" class="text-link">
				<h3 class="my-4 border-b-2 border-current text-lg">Upcoming Events</h3>
			</a>
			<ul class="pa0 ma0">
				{#each upcomingEvents as event}
					<li class="mv2 db leading-tight">
						<a
							class="dark-gray hover-bg-washed-blue hide-child db w-100 min-h2 pv1 ph2 ma0 inline-flex items-center hover:text-blue-500"
							href="events/{event.slug}"
							data-sveltekit-preload-data
						>
							<div class="flex flex-col leading-tight">
								<div class="flex flex-row items-baseline">
									<div class="underline-child f5">{event.name}</div>
								</div>
								<div class="i">
									{event.startTime}
									{#if event.location}
										-
										{event.location.join(', ')}
									{/if}
								</div>
							</div>
							<div class="flex-grow" />
							<div class="child tr f3">&gt;</div>
						</a>
					</li>
				{/each}
			</ul>
		</section>
	{/if}

	<section class="mt6">
		<div>
			<h3 class="my-4 border-b-2 border-current text-lg">Updates</h3>
		</div>
		<ul class="pa0 ma0">
			{#each [{ title: 'Britten Sinfonia Opus 1', linkUrl: 'https://www.brittensinfonia.com/stories/sam-greening', dateRange: '2024', subtitle: undefined }] as update}
				<li class="mv2 db leading-tight">
					<a
						class="dark-gray hover-bg-washed-blue hide-child db w-100 min-h2 pv1 ph2 ma0 inline-flex items-center hover:text-blue-500"
						href={update.linkUrl}
						target="_blank"
						rel="noreferrer"
						data-sveltekit-preload-data
					>
						<div class="flex flex-col leading-tight">
							<div class="flex flex-row items-baseline">
								<div class="underline-child f5">{update.title}</div>
								{#if update.dateRange}
									<div class="ph2">({update.dateRange})</div>
								{/if}
							</div>
							{#if update.subtitle}
								<div class="i">
									{update.subtitle}
								</div>
							{/if}
						</div>
						<div class="flex-grow" />
						<div class="child tr f3">&gt;</div>
					</a>
				</li>
			{/each}
		</ul>
	</section>

	<section>
		<a href="https://soundcloud.com/samgreening" class="text-link">
			<h3 class="my-4 border-b-2 border-current text-lg">Recent Recordings</h3>
		</a>
		<div class="embed soundcloud-player">
			<iframe
				loading="lazy"
				title="SoundCloud Player"
				width="100%"
				height="300"
				scrolling="no"
				frameborder="no"
				allow="autoplay"
				src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1179245461&color=%234f6cec&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
			/>
		</div>
		<div class="mt-2">
			<YouTubeLite id="vSBfrMHkFMI" title="Recording of SHATTER" />
		</div>
	</section>
</main>
